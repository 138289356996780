import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import utils from "@/utils/utils";
import { selBatchGroup, setBatchGroup } from '@/api/openMaintenance';
export default {
  name: 'batchGroups',
  props: {
    type: {
      type: String,
      default: ''
    },
    moduleData: {
      //正式活动
      type: Array,
      defalut: {}
    },
    batchList: {
      type: Array,
      defalut: []
    },
    interval_min: {
      type: Number,
      defalut: 0
    },
    interval_sec: {
      type: Number,
      defalut: 0
    }
  },
  data: function data() {
    return {
      isPC: utils.isPC(),
      groups: [],
      oldgroups: [],
      isAdd: false,
      addData: {
        name: '',
        num_min: '',
        num_max: '',
        start_time: '',
        end_time: ''
      },
      onedisabled: false,
      dialogTitle: '',
      setall: []
    };
  },
  mounted: function mounted() {
    this.getgroups();
  },
  methods: {
    selectMethod: function selectMethod(val, index, type) {
      this.oldgroups[index][type] = Number(val.target.value);
    },
    // 新增分组
    handelAdd: function handelAdd() {
      this.groups.push({
        name: '',
        num_min: null,
        num_max: null,
        start_time: '',
        end_time: ''
      });
      this.oldgroups.push({
        name: '',
        num_min: null,
        num_max: null,
        start_time: '',
        end_time: ''
      });
    },
    // 删除分组
    handelDel: function handelDel(row, index, inx) {
      this.groups.splice(index, 1);
      this.oldgroups.splice(index, 1);
    },
    handelEdit: function handelEdit(row, index, inx) {
      this.dialogTitle = '修改' + row.name;
      var newall = JSON.parse(JSON.stringify(this.groups));
      this.addData = JSON.parse(JSON.stringify(row));
      this.addData.end_time = this.addData.end_time * 1000;
      this.addData.start_time = this.addData.start_time * 1000;

      if (index == 0) {
        this.addData.start_time = this.moduleData[0].start_time;
        this.onedisabled = true;
      } else {
        this.onedisabled = false;
      }

      this.isAdd = true;
      newall.splice(index, 1, this.addData);
      this.setall = newall;
      console.log(this.groups);
    },
    handleSaveAdd: function handleSaveAdd() {
      var _this = this;

      if (this.dialogTitle.includes('修改')) {
        this.allsetGroup(this.setall, 'edit');
        return;
      }

      var newall = JSON.parse(JSON.stringify(this.groups));
      newall.push(this.addData);
      setBatchGroup({
        start_time: this.moduleData[0].start_time,
        groups: newall
      }).then(function (res) {
        if (res.status === 200) {
          _this.getgroups();

          _this.$message.success('添加成功！');

          _this.isAdd = false;
          _this.addData = {
            name: '',
            num_min: '',
            num_max: '',
            start_time: '',
            end_time: ''
          };
        }
      });
    },
    allsetGroup: function allsetGroup(groups, type) {
      var _this2 = this;

      return new Promise(function (resolve, error) {
        var interval_min = _this2.interval_min,
            interval_sec = _this2.interval_sec;
        setBatchGroup({
          interval_min: interval_min,
          interval_sec: interval_sec,
          start_time: _this2.moduleData[0].start_time,
          groups: _this2.oldgroups
        }).then(function (res) {
          _this2.getgroups();

          resolve();
        }).catch(function (err) {
          error();
        });
      });
    },
    getgroups: function getgroups() {
      var _this3 = this;

      selBatchGroup().then(function (res) {
        if (!res.data.list || !res.data.list.length) {
          _this3.groups = [];

          _this3.groups.push({
            name: '',
            num_min: 1,
            num_max: null,
            start_time: _this3.moduleData[0].start_time / 1000,
            end_time: null
          });
        } else {
          _this3.groups = res.data.list || [];
        }

        _this3.oldgroups = JSON.parse(JSON.stringify(_this3.groups));

        for (var i = 0; i < _this3.oldgroups.length; i++) {
          _this3.oldgroups[i].start_time = _this3.oldgroups[i].start_time * 1000 || '';
          _this3.oldgroups[i].end_time = _this3.oldgroups[i].end_time * 1000 || '';
        }
      });
    },
    setfirstStartTime: function setfirstStartTime(val) {
      var interval = val - this.oldgroups[0].start_time;

      for (var i = 0; i < this.oldgroups.length; i++) {
        this.oldgroups[i].start_time = this.oldgroups[i].start_time + interval;
        this.oldgroups[i].end_time = this.oldgroups[i].end_time + interval;
      }

      this.oldgroups[0].start_time = val;
    },
    // 验证分组是否填写完整
    verify: function verify() {
      var _this4 = this;

      return new Promise(function (resolve, error) {
        var errInfo = '';
        var flag = true;

        try {
          _this4.oldgroups.map(function (item) {
            if (item.name === '') {
              flag = false;
              throw '批次分组名称不能为空';
            }

            if (item.num_min === '') {
              flag = false;
              throw item.name + '起始批次不能为空';
            }

            if (item.num_max === '') {
              flag = false;
              throw item.name + '截止批次不能为空';
            }

            if (item.start_time === '') {
              flag = false;
              throw item.name + '开始时间不能为空';
            }

            if (item.end_time === '') {
              flag = false;
              throw item.name + '结束时间不能为空';
            }
          });
        } catch (err) {
          errInfo = err;
        }

        if (flag) {
          _this4.allsetGroup().then(function () {
            resolve();
          }).catch(function () {
            error();
          });
        } else {
          _this4.$message.error(errInfo);

          error();
        }
      });
    }
  }
};